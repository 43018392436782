import { useEffect, useState } from "react";
import * as S from "../../../asset/css/mobileOrderDetailItem.styles.js";
import default_invoice_preview from "../../../asset/images/default-invoice-preview.png";
import circle_minus from "../../../asset/images/circle-minus.png";
import CalculatePricePop from "../../../component/common/Pop/CalculatePricePop.js";
import CalculatorButton from "../../../component/common/Button/CalculatorButton.js";
const formattedValue = (value) => {
  const stringValue = String(value || "");
  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export default function OrderDetailItem(props) {
  const {
    data,
    i,
    isEdit,
    orderData,
    detailInfo,
    setOrderData,
    handleDeleteOriginRow,
  } = props;

  const [name, setName] = useState(data.bidProduct?.name);
  const [standard, setStandard] = useState(data?.bidProduct.standard);
  const [brand, setBrand] = useState(data?.bidProduct.brand);
  const [unit, setUnit] = useState(data?.bidProduct.unit);
  const [count, setCount] = useState(Number(data?.bidProduct.count));
  const [price, setPrice] = useState(Number(data?.price));
  const [displayPrice, setDisplayPrice] = useState(
    formattedValue(String(data?.price))
  );
  const [displayCount, setDisplayCount] = useState(
    formattedValue(String(data?.bidProduct.count))
  );
  const [etc, setEtc] = useState(data?.bidProduct.etc);

  const onChangeName = (e) => setName(e.target.value);
  const onChangeStandard = (e) => setStandard(e.target.value);
  const onChangeBrand = (e) => setBrand(e.target.value);
  const onChangeUnit = (e) => setUnit(e.target.value);
  const onChangeCount = (e) => {
    let value = e.target.value;

    // 소수점 제거
    value = value.split(".")[0];

    value = value.replace(/[^0-9]/g, "");
    if (value.length > 7) {
      value = value.slice(0, 7);
    }

    setCount(value);
    setDisplayCount(formattedValue(value));
  };

  const onChangePrice = (e) => {
    let value = e.target.value;

    // 소수점 제거
    value = value.split(".")[0];

    value = value.replace(/[^0-9]/g, "");
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    setPrice(value);
    setDisplayPrice(formattedValue(value));
  };
  const onChangeEtc = (e) => setEtc(e.target.value);

  // 계산기 모달 관련
  const [openModalId, setOpenModalId] = useState(null);

  const openModal = (id) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  // 금액
  const finalTotalPrice = (count, price) => {
    return (price * count).toLocaleString("ko");
  };

  const handleConfirm = (i, taxPrice) => {
    const convertTaxPrice = String(taxPrice);

    setPrice(taxPrice);
    setDisplayPrice(formattedValue(convertTaxPrice));
    closeModal();
  };
  useEffect(() => {
    setOrderData((prevOrderData) => {
      const newProductInfoList = [...prevOrderData.productInfoList];

      newProductInfoList[i] = {
        ...newProductInfoList[i],
        bidProduct: {
          ...newProductInfoList[i]?.bidProduct,
          name: name,
          standard: standard,
          brand: brand,
          unit: unit,
          count: count,
          etc: etc,
        },
        price: price,
        // price: price,
        // 여기 이미지?
      };

      return { ...prevOrderData, productInfoList: newProductInfoList };
    });
  }, [i, setOrderData, name, standard, brand, unit, count, etc, price, isEdit]);

  return (
    <>
      <tr value={data} key={i}>
        <th>{i + 1}</th>
        {!isEdit && (
          <S.ProductNameWrap>
            {/* {data.productImageUrl && (
              <S.ProductImage
                src={data.productImageUrl}
                alt=""
                onClick={() => window.open(data.productImageUrl, "_blank")}
              />
            )}
            {!data.productImageUrl && (
              <S.DefaultFileImgWrap src={default_invoice_preview} alt="" />
            )} */}
            <span>{data.bidProduct.name}</span>
          </S.ProductNameWrap>
        )}
        {isEdit && data.productImageUrl && (
          <S.ProductNameWrap>
            <S.DeleteRowImg
              src={circle_minus}
              alt=""
              onClick={handleDeleteOriginRow}
            />
            <S.ProductImage
              src={data.productImageUrl}
              alt=""
              onClick={() => window.open(data.productImageUrl, "_blank")}
            />
            <span>{data.bidProduct.name}</span>
          </S.ProductNameWrap>
        )}
        {isEdit && !data.productImageUrl && (
          <S.ProductNameWrap>
            <S.DeleteRowImg
              src={circle_minus}
              alt=""
              onClick={handleDeleteOriginRow}
            />
            <S.TableInput
              onChange={onChangeName}
              defaultValue={name || data?.bidProduct.name}
            />
          </S.ProductNameWrap>
        )}

        {!isEdit && (
          <td>
            <p className="table_overflow_text">
              {data.bidProduct.standard.replace(/, /gi, "\n")}
            </p>
          </td>
        )}
        {isEdit && (
          <td>
            <S.TableInputStandard
              onChange={onChangeStandard}
              defaultValue={standard || data?.bidProduct.standard}
            />
          </td>
        )}

        {/* 브랜드 or 제조국 */}
        {!isEdit && <td>{data.bidProduct.brand}</td>}
        {isEdit && (
          <td>
            <S.TableInput
              onChange={onChangeBrand}
              defaultValue={brand || data?.bidProduct.brand}
            />
          </td>
        )}
        {/* 비고 */}
        {!isEdit && (
          <td>
            <p className="table_overflow_text">{data.bidProduct.etc}</p>
          </td>
        )}
        {isEdit && (
          <td>
            <S.TableInputStandard
              onChange={onChangeEtc}
              defaultValue={etc || data?.bidProduct.etc}
            />
          </td>
        )}

        {/* 단위 */}
        {!isEdit && <td>{data.bidProduct.unit}</td>}
        {isEdit && (
          <td>
            <S.TableInput
              onChange={onChangeUnit}
              defaultValue={unit || data?.bidProduct.unit}
            />
          </td>
        )}

        {/* 수량 */}
        {!isEdit && (
          <td>
            {Number(data?.bidProduct.count)?.toLocaleString("ko") ||
              Number(
                orderData?.productInfoList[i]?.bidProduct?.count
              )?.toLocaleString("ko")}
          </td>
        )}
        {isEdit && (
          <td>
            <S.TableInputNumber
              type="text"
              onChange={onChangeCount}
              value={displayCount || count}
              // defaultValue={count || Number(data?.bidProduct.count)}
            />
          </td>
        )}

        {/* 고객사 납품 단가 */}
        {!isEdit && (
          <td>
            {Number(data?.price)?.toLocaleString("ko") ||
              Number(orderData?.productInfoList[i]?.price)?.toLocaleString(
                "ko"
              )}
          </td>
        )}
        {isEdit && (
          <td>
            <div className="table_calculate_box">
              <S.TableInputNumber
                type="text"
                onChange={onChangePrice}
                value={displayPrice || price}
              />
              <CalculatorButton onClick={() => openModal(i)} />
            </div>

            {openModalId === i && (
              <CalculatePricePop
                closeCalcModal={closeModal}
                name={data.bidProduct.name}
                onConfirm={(taxPrice) => handleConfirm(i, taxPrice)}
              />
            )}
          </td>
        )}

        {/* 단가 * 수량 (고객사 납품단가) */}
        <td>
          {!isEdit &&
            (
              Number(data?.bidProduct.count) * Number(data?.price)
            ).toLocaleString("ko")}
          {isEdit &&
            finalTotalPrice(
              orderData?.productInfoList[i]?.bidProduct?.count,
              orderData?.productInfoList[i]?.price
            )}
        </td>
      </tr>
    </>
  );
}
