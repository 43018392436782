import React from "react";
import ReactTooltip from "react-tooltip";
import calculator from "../../../asset/images/icon_calculator.svg";

const CalculatorButton = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="table_calculate_img_box"
      data-tip
      data-for="tooltip"
    >
      <img src={calculator} alt="Calculator" />
      <ReactTooltip
        id="tooltip"
        place="bottom"
        effect="solid"
        textColor="#020202"
        className="calculate_tooltip"
        backgroundColor="#e7ebf0"
      >
        단가입력 계산기
      </ReactTooltip>
    </div>
  );
};

export default CalculatorButton;
