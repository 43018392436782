import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

//css
import "../../asset/css/common.css";

//img
import footerIcon1 from "../../asset/images/side_footer_icon1.png";
import footerIcon2 from "../../asset/images/side_footer_icon2.png";
import footerIcon3 from "../../asset/images/side_footer_icon3.png";
import footerIcon4 from "../../asset/images/side_footer_icon4.png";
import arrowDownIcon from "../../asset/images/arrow_down_icon.png";
import { ReactComponent as NewIcon } from "../../asset/images/new_icon.svg";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";

//header 내용
import { sideList, sideFooterList } from "../../asset/js/dummy";

//lib
import swal from "sweetalert";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { roundToNearestMinutes } from "date-fns";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { signToken } = useSelector((state) => ({
    signToken: state.token,
  }));

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const [activeMenu, setActiveMenu] = useState({ id: 0, active: false });
  const [nowUrl, setNowUrl] = useState(pathname);
  const [navList, setNavList] = useState();

  const [isOpen, setIsOpen] = useState(false);

  //nav 이벤트
  const activeFunction = (location) => {
    const activeList = sideList.map((item) =>
      item.uri.indexOf(location.split("/")[1]) > 0
        ? { ...item, active: true }
        : { ...item }
    );
    setNavList(activeList);
    const filterList = activeList.filter((item) => item.active);
    if (filterList.length > 0) {
      setActiveMenu(filterList[0]);
    }
    setNowUrl(pathname);
  };

  //로그아웃
  const logoutFnc = () => {
    swal({
      title: "LOGOUT",
      text: "로그아웃 하시겠습니까?",
      icon: "info",
      buttons: true,
      // dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(actions.setToken(false));
        dispatch(actions.setUser(userDefault));
      } else {
        swal("취소하셨습니다.");
      }
    });
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  window.addEventListener("beforeinstallprompt", (e) => {
    console.log(e);
    e.preventDefault();
    console.log(e);
    // deferredPrompt = e;
    setDeferredPrompt(e);
  });

  // 앱 다운로드 관련
  const appDownloadFnc = () => {
    if (!deferredPrompt) {
      swal(
        "바탕화면에 바로가기를 추가할 수 없습니다.",
        "이미 앱을 설치하셨거나 앱을 설치할 수 없는 환경입니다",
        "error"
      );
    } else {
      swal({
        title: "바탕화면에 바로가기 앱을 추가하시겠습니까?",
        text: "확인을 누르면 바로가기 앱이 바탕화면에 설치됩니다.",
        icon: "info",
        buttons: true,
        // dangerMode: false,
      }).then(async (willDelete) => {
        if (willDelete) {
          const result = await deferredPrompt.prompt();
          if (result.outcome === "accepted") {
            console.log("사용자가 설치를 수락했습니다.");
            // 추가 작업
            setDeferredPrompt(null); // 프롬프트 완료 후 초기화
          } else {
            swal(
              "설치 실패",
              "바로가기를 설치하지 못했습니다. \n상단 설치버튼을 눌러 설치를 완료해주세요.",
              "error"
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setActiveMenu({ ...activeMenu, id: 0 });
    }
    activeFunction(pathname);
    // setNaviSwitch(false);
  }, [pathname]);

  const activeTabFnc = (elem) => {
    const pattern = /(estimate|contract|order|invoice|qna|all_history)/i;

    const el = elem.match(pattern);
    const now = nowUrl.match(pattern);

    const filter_elem = el ? el[0] : null;
    const filter_now = now ? now[0] : null;

    if (filter_elem === filter_now) {
      return true;
    } else {
      return false;
    }
  };

  return signToken && !isResponsive ? (
    <>
      <div className="empty_section"></div>
      <div className="side_wrap_first">
        <div className="side_wrap">
          <div
            className="header_left side_top"
            onClick={() => {
              navigate("/");
              setActiveMenu({ ...activeMenu, id: 0 });
            }}
          >
            <h1>{/* 로고이미지 */}</h1>
            <p>공급사</p>
          </div>
          <div className="side_inner">
            <div className="side_main_inner">
              <motion.a
                className="estimate_request_btn"
                onClick={() => {
                  navigate("/request/1");
                }}
              >
                입찰 공고 보기
              </motion.a>
              <AnimateSharedLayout>
                <motion.ul layout initial={{ borderRadius: 25 }}>
                  <motion.li
                    layout
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    initial={{ borderRadius: 10 }}
                  ></motion.li>
                </motion.ul>
              </AnimateSharedLayout>

              {sideList.map(({ id, uri, mainNav, active, Icon }) => {
                return (
                  <div
                    className={
                      activeTabFnc(uri) ? "side_elem" : "side_elem_off"
                    }
                  >
                    {/* {data.id !== 1 ? */}
                    <Link
                      className={
                        activeTabFnc(uri) ? "main_elem" : "main_elem_off"
                      }
                      to={uri}
                      onClick={() => {
                        setActiveMenu({ active: true });
                        setNowUrl(uri);
                      }}
                      // className={all.active ? 'active_menu' : 'unactive_menu'}
                    >
                      <Icon />
                      <span>{mainNav}</span>
                    </Link>
                    {/* } */}
                  </div>
                );
              })}
            </div>

            <div className="side_footer_container">
              <div className="side_line"></div>

              <div className="side_footer_inner">
                <Link to="/look" className="side_footer">
                  {/* <img src={footerIcon1}/> */}
                  <span>인근현장찾기</span>
                </Link>
                <Link to="/my_page/notice" className="side_footer">
                  {/* <img src={footerIcon2}/> */}
                  <span>공지사항</span>
                  <NewIcon color="#ff6600" />
                </Link>
                <Link
                  to=""
                  className="side_footer"
                  onClick={(e) => {
                    e.preventDefault();
                    swal(
                      "문의",
                      "문의사항은 032-205-0479로 연락주시길 바랍니다.\n 친절히 답변드리겠습니다.",
                      "info"
                    );
                  }}
                >
                  <span>FAQ / 고객문의</span>
                </Link>
                <Link to="" className="side_footer">
                  <span onClick={appDownloadFnc}>바탕화면에 바로가기 추가</span>
                </Link>
                <Link to="" className="side_footer">
                  <span onClick={logoutFnc}>로그아웃</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
