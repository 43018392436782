import { useEffect, useState } from "react";
import * as S from "../../../asset/css/mobileOrderDetailItem.styles.js";
import circle_minus from "../../../asset/images/circle-minus.png";
import CalculatePricePop from "../../../component/common/Pop/CalculatePricePop.js";
import CalculatorButton from "../../../component/common/Button/CalculatorButton.js";

const formattedValue = (value) => {
  const stringValue = String(value || "");
  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function OrderAddRow(props) {
  const { setOrderData, detailInfo, idx, odList, handleDeleteRow } = props;

  const [name, setName] = useState();
  const [standard, setStandard] = useState();
  const [brand, setBrand] = useState();
  const [unit, setUnit] = useState();
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [etc, setEtc] = useState();
  const [displayPrice, setDisplayPrice] = useState(formattedValue(price) || 0);

  const onChangeName = (e) => setName(e.target.value);
  const onChangeStandard = (e) => setStandard(e.target.value);
  const onChangeBrand = (e) => setBrand(e.target.value);
  const onChangeUnit = (e) => setUnit(e.target.value);
  const onChangeCount = (e) => {
    let value = e.target.value;
    // 소수점 제거
    value = value.split(".")[0];
    value = value.replace(/[^0-9]/g, "");
    if (value.length > 7) {
      value = value.slice(0, 7);
    }

    setCount(value);
  };
  const onChangePrice = (e) => {
    let value = e.target.value;

    // 소수점 제거
    value = value.split(".")[0];

    value = value.replace(/[^0-9]/g, "");
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    setPrice(value);
    setDisplayPrice(formattedValue(value));
  };
  const onChangeEtc = (e) => setEtc(e.target.value);

  // 계산기 모달 관련
  const [openModalId, setOpenModalId] = useState(null);

  const openModal = (id) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const handleConfirm = (idx, taxPrice) => {
    const convertTaxPrice = String(taxPrice);

    setPrice(taxPrice);
    setDisplayPrice(formattedValue(convertTaxPrice));
    closeModal();
  };

  useEffect(() => {
    setOrderData((prevOrderData) => {
      const newProductInfoList = [...prevOrderData.productInfoList];

      newProductInfoList[idx + odList?.length] = {
        ...newProductInfoList[idx + odList?.length],
        bidProduct: {
          ...newProductInfoList[idx + odList?.length]?.bidProduct,
          productNo: null,
          name: name,
          standard: standard || "-",
          brand: brand || "-",
          unit: unit || "-",
          count: count,
          etc: etc || "-",
        },
        price: price,
        priceOptionNo: null,
      };

      return { ...prevOrderData, productInfoList: newProductInfoList };
    });
  }, [
    odList.length,
    count,
    etc,
    idx,
    name,
    brand,
    price,
    setOrderData,
    standard,
    unit,
  ]);

  useEffect(() => {
    return () => {
      setOrderData((prevOrderData) => {
        const newProductInfoList = [...prevOrderData.productInfoList];
        newProductInfoList.splice(idx + odList?.length, 1);
        return { ...prevOrderData, productInfoList: newProductInfoList };
      });
    };
  }, [odList.length, idx, setOrderData]);

  return (
    <tr>
      <th>{odList.length + idx + 1}</th>
      <S.ProductNameWrap>
        <S.DeleteRowImg src={circle_minus} alt="" onClick={handleDeleteRow} />
        <S.TableInputName type="text" onChange={onChangeName} />
      </S.ProductNameWrap>
      <td>
        <S.TableInputStandard type="text" onChange={onChangeStandard} />
      </td>
      <td>
        <S.TableInput type="text" onChange={onChangeBrand} />
      </td>
      <td>
        <S.TableInputStandard type="text" onChange={onChangeEtc} />
      </td>
      <td>
        <S.TableInput type="text" onChange={onChangeUnit} />
      </td>
      <td>
        <S.TableInputNumber onChange={onChangeCount} value={count} />
      </td>
      <td style={{ position: "relative" }}>
        <div className="table_calculate_box">
          <S.TableInputNumber
            onChange={onChangePrice}
            value={displayPrice || price}
          />
          <CalculatorButton onClick={() => openModal(idx)} />
        </div>

        {openModalId === idx && (
          <CalculatePricePop
            closeCalcModal={closeModal}
            name={name}
            onConfirm={(taxPrice) => handleConfirm(idx, taxPrice)}
          />
        )}
      </td>

      <td>
        {(!Number.isNaN(count * price) &&
          (count * price)?.toLocaleString("ko")) ||
          0}
      </td>
    </tr>
  );
}
